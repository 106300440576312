<template>
	<div class="tool-page">
		<div class="menu-scroll-box">
			<vue-scroll>
				<div class="menu-list-box">
					<div class="menu-box" v-for="(item,inde) in butList" :key="inde">
						<div class="type-name">{{item.type}}</div>
						<div class="menu-list">
							<div class="menu pointer" v-for="(i,ind) in item.menu" :key="ind" @click="butClick($event,i.pathName)">
								<span :class="'icon-size iconfont grey ' + i.icon" :style="{'color': i.color ? i.color : '#909399'}"></span>
								<p>{{i.name}}</p>
							</div>
						</div>
					</div>
				</div>
			</vue-scroll>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			butList:[],//按钮数据
		};
	},
	created() {
		this.butList = [];
		var info = JSON.parse(localStorage.data);
		var len = info.myAuthorities.length;
		var butList = [];
		// // console.log(info)
		
		for(let i = 0; i < len; i++){
			let item = info.myAuthorities[i];
			if(item.id == 54){
				let clen = item.children.length;
				this.constData.toolRouter.forEach((val)=>{
					let mlen = val.menu.length;
					let obj = {
						menu:[],
					};
					for(let l = 0; l < mlen; l++){
						let m = val.menu[l];
						for(let k = 0; k < clen; k++){
							let c = item.children[k];
							if(c.id == m.id){
								if(!obj.hasOwnProperty('type')){
									obj.type = val.type;
								}
								obj.menu.push(m);
								break;
							}
						}		
					}
					if(obj.menu.length != 0){
						this.butList.push(obj);
					}
				})
			}
		}
		// console.log(this.butList)
	},
	mounted() {},
	computed: {},
	methods: {
		butClick(e,data){
			$(".menu").removeClass('action');
			$(e.currentTarget).addClass('action');
			// console.log(data)
			this.toRouter(data);
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.tool-page {
	width: 100%;
	height: 100%;

	.menu-scroll-box {
		width: 100%;
		height: 100%;

		.menu-list-box {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;

			.menu-box {
				width: 100%;
				border-bottom: thin solid #000000;
				margin: 10px 0 10px 0;

				.type-name {
					font-size: 16px;
					font-weight: 600;
					margin-left: 10px;
				}
				
				.grey{
					color: #909399;
				}

				.menu-list {
					width: calc(100% - 10px);
					padding-left: 10px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;

					.menu {
						width: 90px;
						// border: thin solid #0086B3;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin: 10px 10px 5px 0;
						padding: 10px 5px;
						border-radius: 4px;
						
						.icon-size{
							font-size: 25px;
						}

						p {
							text-align: center;
							font-size: 13px;
							margin-top: 5px;
						}
					}
					
					.action{
						background-color: #EBEEF5 !important;
						color: #409EFF !important;
						
						.grey{
							color: #409EFF !important;
						}
					}
				}
			}
		}
	}
}
</style>
